import React, { useRef, useCallback, useState, useEffect } from 'react';
// import QRCode from "react-qr-code";
import { useParams } from 'react-router-dom';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Form } from '@unform/web';
import { FiFile } from 'react-icons/fi';
// import { BsQuestionCircle } from 'react-icons/bs';
import * as Yup from 'yup';

import { useToast } from '~/hooks/toast';
import getValidationErrors from '~/utils/getValidationErrors';
import Input from '~/components/Input';
import Button from '~/components/Button';
import Index from '~/pages/Index';

import loadingGif from '~/assets/img/loadingMusic.gif';
import Check from '~/assets/img/check.svg';
import api from '~/services/api';
import * as S from './styles';
// import avatar from '~/assets/img/avatar.png';


const ValidacaoVoucher = () => {
  const { hashVoucher } = useParams();
  const formRef = useRef(null);

  // const history = useHistory();
  const { addToast } = useToast();

  const [disable, setDisable] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState(false);

  // const [dataInfoCertificate, setdataInfoCertificate] = useState({
  //   fileCertificate: '',
  //   fileMusic: ''
  // });

  const findVoucherByHash = useCallback(async (data) => {

    try {
      setLoading(true);
      setShowSection(false);
      setDisable(disable === '' ? 'active' : '');

      const response = await api.post(`vouchers/validate/${data.hashVoucher}`);
      const result = response.data;
      console.log(result)
      // setdataInfoCertificate({
      //   fileCertificate: result.fileCertificate,
      //   fileMusic: result.fileMusic,
      //   linkMusic: result.linkMusic,
      //   linkFileP7S: result.linkFileP7S,
      //   linkQrCades: result.linkQrCades,
      //   linkQrPades: result.linkQrPades
      // })

      setLoading(false);
      setShowSection(true);
      setDisable(disable === 'active' ? '' : '');
      addToast({
        type: 'success',
        title: 'Voucher válido',
        description: 'Voucher validado com sucesso!',
      });
      return;

    } catch (error) {
      setLoading(false);
      setShowSection(false);
      setDisable(disable === 'active' ? '' : '');

      if (error.message === 'Network Error') {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'Serviço de dados indisponível no momento, favor tente mais tarde!',
        });
        return;
      }

      if (error.message) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: error.response ?
          error.response.data.message : 'Erro ao validar voucher',
        });
      }

    }
  }, [addToast, disable])

  useEffect(() => {

    if(hashVoucher) {

      formRef.current.setData({
        hashVoucher
      })

      findVoucherByHash({hashVoucher})
    } else {
      formRef.current.setData({})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        // console.log(data);
        const schema = Yup.object().shape({
          hashVoucher: Yup.string()
            .required('Digite o voucher')
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await findVoucherByHash(data)
      } catch (err) {

        setLoading(false);
        setShowSection(false);
        setDisable(disable === 'active' ? '' : '');

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current.setErrors(errors);

        }
      }

    },
    [findVoucherByHash, disable]
  );


  return (
    <Index>
      <S.Header>
        <div className="nameMusic">
          <h1>Validação de Voucher</h1>
        </div>
      </S.Header>


      {showSection ? (
        <S.Section>
          <S.DivSectionGlobal>
            <div className="sectionSucess">
                <img src={Check} alt="Voucher válido" />
                <span className="sucess">Voucher Válido</span>
            </div>
            <hr size="20"/>

            {/* <p style={{padding: '0px 0px 25px'}}>
              Este certificado é válido, obedecendo rigorosamente a LEI 9.610/98 e a medida
              provisória nº 2.200-2, que estabeleceu o ICP Brasil.
            </p>

            <h1>Dados do Certificado</h1>

            <div className='infoCertificate'>
              <div className='dataForm'>
                <Form id='formInfo' style={{width: '100%'}}>

                  <div className="inputsRow">
                    <div className="divColumn48">
                      <Input label="Certificado:" type="text"
                        value={dataInfoCertificate.fileCertificate}
                        name="fileCertificate" id="fileCertificate"
                        maxLength="25" disabled style={{
                          cursor: 'no-drop',
                          color: '#7C7C7D',
                        }}/>
                    </div>
                  </div>
                  <div className="inputsRow">
                    <div className="divColumn48">
                      <Input label="Música:" type="text"
                        value={dataInfoCertificate.fileMusic}
                        name="fileMusic" id="fileMusic"
                        maxLength="25" disabled style={{
                          cursor: 'no-drop',
                          color: '#7C7C7D',
                        }}/>
                    </div>
                  </div>
                </Form>
              </div>
            </div>

            <div className='footerButtons'>
              <div className='sectionButtons'>
                <a className='link01' href={dataInfoCertificate.linkMusic}>Visualizar Composição</a>
                <a className='link02' href={dataInfoCertificate.linkFileP7S} download>Baixar Arquivo P7S</a>
                <a className='link03' href={dataInfoCertificate.linkITI}>Verificador de Conformidade</a>
              </div>
            </div> */}
          </S.DivSectionGlobal>
        </S.Section>
      ) : (<div />)}

      <S.Section>
        <S.DivSectionGlobal>
          <h1>Voucher AcordeOn®</h1>
          <p>Digite o voucher para validar a autenticidade do mesmo em nosso sistema e para ter acesso aos créditos.</p>

          <Form id='formHash' ref={formRef} onSubmit={handleSubmit}  autocomplete="off">
            <Input icon={FiFile} name="hashVoucher" id="hashVoucher" type="text" placeholder="Voucher" />

            {loading && <img src={loadingGif} alt="Carregando..." width="50px" />}
            <Button type="submit" disabled={loading} className={disable}>
              Validar
            </Button>
          </Form>

        </S.DivSectionGlobal>
      </S.Section>

    </Index>
  );
};

export default ValidacaoVoucher;
