import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { BsQuestionCircle, BsXCircle, BsX, BsPencil, BsSearch } from 'react-icons/bs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import Dropzone from 'react-dropzone';
import CPF from 'cpf';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import * as Yup from 'yup';

import { Scope } from '@unform/core';
import { RiSave2Line } from 'react-icons/ri';

import getValidationErrors from '~/utils/getValidationErrors';
import { lower } from '~/utils/lower';

import Index from '~/pages/Index';

import { ReactComponent as MusicIcon } from '~/assets/img/musicIcon.svg';
import loadingGif from '~/assets/img/loadingMusic.gif';

import { useProfile } from '~/hooks/profile';
import { useToast } from '~/hooks/toast';

import Loading from '~/components/Loading';
import Input from '~/components/Input';
import Posts from '~/components/Posts';
import Modal from '~/components/Modal';
import InputM from '~/components/InputM';
import ShowError from '~/components/ShowError';
import Checkbox from '~/components/Checkbox';
import TermsUse from '~/components/TermsUse';

import api from '~/services/api';
import { formatTextCapitalize } from '~/utils/formatTextCapitalize';
import * as S from './styles';

export default function Data() {
  const formRef = useRef(null);
  const history = useHistory();
  const { dataPerson, dataProfile } = useProfile();
  const { addToast } = useToast();
  const [word, setWord] = useState('');
  const [genre, setGenre] = useState([]);
  const [genreID, setGenreID] = useState([]);
  const [genreMusic, setGenreMusic] = useState([]);
  const [modalShow, setModalShow] = useState('');
  const [active, setActive] = useState('');
  const [name, setName] = useState('');
  const [erro, setErro] = useState(false);
  const [erroLetter, setErroLetter] = useState(false);
  const [erroMusic, setErroMusic] = useState(false);
  const [musicName, setMusicName] = useState(false);
  const [failLetterAndMusic, setFailLetterAndMusic] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const [letters, setLetters] = useState('');
  const [split, setSplit] = useState(['']);
  const [countCoID, setCountCoID] = useState(1);
  const [countInID, setCountInID] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [values, setValues] = useState({
    co_author: [],
    interpreter: [],
  });

  useEffect(() => {
    if (loading) {
      setModalShow('uploadMusic')
    } else {
      setModalShow('')
    }

  }, [loading])

  useEffect(() => {
    if (loadingRegister) {
      setModalShow('registeringMusic')
    } else {
      setModalShow('')
    }

  }, [loadingRegister])

  const addInputcoAutor = e => {
    e.preventDefault();
    setCountCoID(countCoID + 1);
    setValues({
      ...values,
      co_author: [
        ...values.co_author,
        {
          id: countCoID,
          name: '',
          cpf: '',
        },
      ],
    });
  };

  const addInputcoAutorName = (e, position) => {
    const { value } = e.target;

    const newNameCoAuthor = values.co_author.map((wek, index) => {
      return position === index ? { ...wek, name: value } : wek;
    });

    setValues({
      ...values,
      co_author: newNameCoAuthor,
    });
  };

  const addInputcoAutorCPF = (e, position) => {
    const { value } = e.target;

    const CPFnotCaracters = value.replace(/[^0-9]/g, '');

    const newCPFCoAuthor = values.co_author.map((wek, index) => {
      return position === index ? { ...wek, cpf: CPFnotCaracters } : wek;
    });

    setValues({
      ...values,
      co_author: newCPFCoAuthor,
    });
  };

  const addInputInterpreter = e => {
    e.preventDefault();

    setCountInID(countInID + 1);

    setValues({
      ...values,
      interpreter: [
        ...values.interpreter,
        {
          id: countInID,
          name: '',
          cpf: '',
        },
      ],
    });
  };

  const addInputInterpreterName = (e, position) => {
    const { value } = e.target;

    const newNameInterpreter = values.interpreter.map((wek, index) => {
      return position === index ? { ...wek, name: value } : wek;
    });

    setValues({
      ...values,
      interpreter: newNameInterpreter,
    });
  };

  const addInputInterpreterCPF = (e, position) => {
    const { value } = e.target;

    const CPFnotCaracters = value.replace(/[^0-9]/g, '');

    const newCPFInterpreter = values.interpreter.map((wek, index) => {
      return position === index ? { ...wek, cpf: CPFnotCaracters } : wek;
    });

    setValues({
      ...values,
      interpreter: newCPFInterpreter,
    });
  };

  // CORRIGIR
  const removeInputcoAutor = position => {
    setValues({
      ...values,
      co_author: [...values.co_author.filter((_, index) => index !== position)],
    });
  };

  const removeInputInterpreter = position => {
    setValues({
      ...values,
      interpreter: [
        ...values.interpreter.filter((_, index) => index !== position),
      ],
    });
  };

  useEffect(() => {
    if (dataPerson?.fullname === null) {
      history.push('/dashboard/perfil');
    }
    if (dataProfile.wallet?.creditUsed <= 0) {
      history.push('/dashboard/adicionar-creditos/planos');
    }
  }, [dataPerson, dataProfile, history]);

  useEffect(() => {
    api
      .get('/categories/list/music')
      .then(category => setGenreMusic(category.data))
  }, []);

  const addGenreMusic = (idGenre, nameGenre) => {
    setGenreID(idGenre);
    setGenre([nameGenre]);
    setActive(nameGenre);
    setErro(false);
  };

  const removeGenreMusic = position => {
    setGenre([...genre.filter((_, index) => index !== position)]);
    setActive('');
    setGenreID('');
  };

   const handleFileChange = async (e) => {
    const { files } = e.target;
    const file = files[0];

    const extension = file.name.split('.').pop()

    if (extension === "mp4") {
      return addToast({
        type: 'error',
        title: 'Formato mp4 não é aceito',
        description:
        'Converta o áudio para o formato mp3 e tente novamente. Qualquer dúvida entre em contato com nosso suporte.'
      });
    }

    if (files.length > 1) {
      return setName('Selecione apenas um áudio');
    }

    const tenMB = 10485760 // 10 MB

    if (file.size > tenMB) {
      return addToast({
          type: 'error',
          title: 'Erro no registro',
          description:
            'O áudio deverá ser até 10 MB',
        });
    }


    try{
      setLoading(true);
      setSelectedFile(null);

      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedFile({ file, music: event.target.result, urlMP3: URL.createObjectURL(file)});
      };
      reader.readAsDataURL(file);

      const data = new FormData();

      data.append('musicFile', file);

      const successUpload = await api.post('/music/upload', data)

      setUploadFile(successUpload.data);

      setLoading(false);

      if (files.length > 0) {
        setName(file.name);
      }

      const { message } = successUpload.data;

      return addToast({
        type: 'success',
        title: `Upload de música`,
        description: message,
      });

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no upload',
        description:
        'Erro ao tentar anexar o áudio, verifique se a arquivo é áudio e tem tamanho até 10 MB!',
      });
      setLoading(false);
      return console.error('error', error.response.data.message);
    }
  };

  const handleSubmit = async formData => {
    try {
      formRef.current.setErrors({});
      const genero = genreID.length === 0 ? '' : genreID;
      const CPFnotCaracters = formData.CPF_author.replace(/[^0-9]/g, '');
      const coAuthorCapitalize = values.co_author.map(co => {
        return {
          ...co,
          name: formatTextCapitalize(co.name)
        }
      })
      const coInterpreterCapitalize = values.interpreter.map(inter => {
        return {
          ...inter,
          name: formatTextCapitalize(inter.name)
        }
      })



      const schema = Yup.object().shape({
        title: Yup.string().required('Título da música obrigatório.'),
        terms: Yup.boolean().oneOf(
          [true],
          'Você deve aceitar os termos e condições.'
        ),
        myAuthor: Yup.boolean().oneOf(
          [true],
          'Você deve aceitar para confirmar que a composição é de sua autoria.'
        ),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      const validCPFcoAutor = values?.co_author?.map(valid => valid.cpf);
      const validCPFInterpreter = values?.interpreter?.map(valid => valid.cpf);

      for(let i = 0; i < validCPFcoAutor.length; i += 1 ) {

        if (!CPF.isValid(validCPFcoAutor[i], true)) {
          addToast({
            type: 'error',
            title: 'Erro no registro',
            description: 'Você precisa preencher todos os campos indicados!',
          });
          return formRef.current.setFieldError(`co_author.cpfco-autor${[i+1]}`, 'CPF incompleto');
        }

        if (!CPF.isValid(validCPFcoAutor[i])) {
          addToast({
            type: 'error',
            title: 'Erro no registro',
            description: 'O CPF do co-autor fornecido é inválido, tente novamente!',
          });
          return formRef.current.setFieldError(`co_author.cpfco-autor${[i+1]}`, 'CPF inválido');
        }
      }

      for(let i = 0; i < validCPFInterpreter.length; i += 1 ) {

        if (!CPF.isValid(validCPFInterpreter[i], true)) {
          addToast({
            type: 'error',
            title: 'Erro no registro',
            description: 'Você precisa preencher todos os campos indicados!',
          });
          return formRef.current.setFieldError(`interpreter.cpf-interpreter${[i+1]}`, 'CPF incompleto');
        }

        if (!CPF.isValid(validCPFInterpreter[i])) {
          addToast({
            type: 'error',
            title: 'Erro no registro',
            description: 'O CPF do intérprete fornecido é inválido, tente novamente!',
          });
          return formRef.current.setFieldError(`interpreter.cpf-interpreter${[i+1]}`, 'CPF inválido');
        }
      }


      if (genero === '') {
        addToast({
          type: 'error',
          title: 'Erro no registro',
          description:
            'Você precisa preencher um estilo musical!',
        });
        return setErro(true);
      }
      setErro(false);

      if ((letters === '' || letters[0] === '') && name === '') {
        addToast({
          type: 'error',
          title: 'Erro no registro',
          description:
            'Você precisa adicionar pelo menos um dos campos indicados (letra ou música)!',
        });
        return setFailLetterAndMusic(true);
      }
      setFailLetterAndMusic(false);

      // Inicio tratando a letra

      if (letters !== '' || letters[0] !== '') {
        setErroLetter(true);
      } else {
        setErroLetter(false);
      }

      if ((letters === '' || letters[0] === '') && erroLetter === false) {
        return setModalShow('failLetter');
      }
      // Fim tratando a letra

      // Inicio tratando a música
      const showModalFailMusic = () => {
        setModalShow('failMusic');
      };

      if (name === '') {
        setErroMusic(true);
      } else {
        setErroMusic(false);
      }

      if (name === '' && erroMusic === false && musicName === false) {
        return showModalFailMusic();
      }
      // Fim tratando a música

      setLoadingRegister(true);

      // console.log('data', {
      //   ...formData,
      //   CPF_author: CPFnotCaracters,
      //   styles: [genero],
      //   fileId: uploadFile.fileId,
      //   musicFolder: uploadFile.musicFolder,
      //   fileName: name,
      //   letter: split,
      //   co_author: coAuthorCapitalize,
      //   interpreter: coInterpreterCapitalize,
      // });

      await api.post('/music/create', {
        ...formData,
        CPF_author: CPFnotCaracters,
        styles: [genero],
        fileId: uploadFile.fileId,
        musicFolder: uploadFile.musicFolder,
        fileName: name,
        letter: split,
        co_author: coAuthorCapitalize,
        interpreter: coInterpreterCapitalize,
      });

      setLoadingRegister(false);

      document.location.href = `${process.env.REACT_APP_URL}/dashboard/registrar-musica/registro-enviado`;
      return null
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current.setErrors(errors);
      }

      setLoadingRegister(false);

      addToast({
        type: 'error',
        title: 'Erro no registro',
        description: 'Falha no registro da música, revise todos os campos caso persista entre em contato com nosso suporte!',
      });
      return null
    }
  };

  const updateLetters = async () => {
    const lets = letters === '' ? [''] : letters;
    // console.log('lets', lets);
    // console.log('letters2',typeof letters);
    // console.log('replaceText', replaceText);
    if (lets.length > 1) {
      const replaceText = lets.replace(/(<([^>]+)>)/gi, '\n');
      setSplit(replaceText.split('\n'));
    }
  }

  const fecharSalvarLetter = () => {
    updateLetters();
    setModalShow('')
  }

  const continueRegisterLetter = () => {
    const dataForm = formRef.current.getData();
    setErroLetter(true);
    setModalShow('');
    handleSubmit(dataForm);
  };

  const continueRegisterMusic = () => {
    const dataForm = formRef.current.getData();
    setErroMusic(true);
    setMusicName(true);
    setModalShow('');
    handleSubmit(dataForm);
  };



  return (
    <Index>
      <Posts />
      <S.BackgroundSection className="blockPage">
        <h1>Registro de música:</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="oneInputsRow">
            <Input label="Título da música:" type="name" name="title" />
          </div>
          <div className="twoInputsRow">
            <div className="divColumn">
              <Input
                label="Autor da música:"
                type="text"
                name="name_author"
                value={dataPerson.fullname || ''}
                disabled
                style={{ cursor: 'no-drop', color: '#7C7C7D' }}
              />
            </div>
            <div className="divColumn">
              <InputM
                label="CPF do Autor:"
                mask="999.999.999-99"
                type="text"
                name="CPF_author"
                value={dataPerson.cpf || ''}
                disabled
                style={{ cursor: 'no-drop', color: '#7C7C7D' }}
              />
            </div>
          </div>

          <div className="hr">
            <div className="buttonsInserts">
              <button type="button" onClick={addInputcoAutor}>
                + Inserir co-autor
              </button>
            </div>

            {values.co_author.map((coauthor, index) => (
              <div className="twoInputsRow" key={coauthor.id}>
                <Scope path="co_author">
                  <div className="divAddColumn">
                    <Input
                      label={`Co-autor ${index + 1} :`}
                      type="text"
                      name="name"
                      textTranform="capitalize"
                      onChange={e => addInputcoAutorName(e, index)}
                    />
                  </div>

                  <div className="divAddColumn">
                    <InputM
                      label={`CPF do Co-autor ${index + 1} :`}
                      type="text"
                      name={`cpfco-autor${index + 1}`}
                      mask="999.999.999-99"
                      onChange={e => addInputcoAutorCPF(e, index)}
                    />
                  </div>

                  <button
                    type="button"
                    aria-label='Remover Co-Autor'
                    onClick={() =>
                      removeInputcoAutor(index)}
                  >
                    <BsXCircle />
                  </button>
                </Scope>
              </div>
            ))}
          </div>
          <div className="hr">
            <div className="buttonsInserts">
              <button type="button" onClick={addInputInterpreter}>
                + Inserir intérprete
              </button>
            </div>

            {values.interpreter.map((interpreter, index) => (
              <div className="twoInputsRow" key={interpreter.id}>
                <Scope path="interpreter">
                  <div className="divAddColumn">
                    <Input
                      label={`Intérprete ${index + 1} :`}
                      type="text"
                      name="name"
                      textTranform="capitalize"
                      onChange={e => addInputInterpreterName(e, index)}
                    />
                  </div>
                  <div className="divAddColumn">
                    <InputM
                      label={`CPF do Intérprete ${index + 1} :`}
                      type="text"
                      name={`cpf-interpreter${index + 1}`}
                      mask="999.999.999-99"
                      onChange={e => addInputInterpreterCPF(e, index)}
                    />
                  </div>

                  <button
                    type="button"
                    aria-label='Remover intérprete'
                    onClick={() => {
                      removeInputInterpreter(index);
                    }}
                  >
                    <BsXCircle />
                  </button>
                </Scope>
              </div>
            ))}
          </div>

          <div className="divAlignItems">
            <div className="styleMusic">
              <span>Pesquise o estilo musical e clique para selecionar:</span>
              <div className="inputIcon">
                  <input
                    type="text"
                    value={word}
                    placeholder="Pesquisar"
                    onChange={e => setWord(e.target.value)}
                  />
                  <BsSearch size={13} />
                </div>
              {erro && (
                <ShowError description="Selecione um estilo músical abaixo" />
              )}
              <div className="genre">
                {genre.map((music, index) => (
                  <div key={music}>
                    <li>
                      {music}
                      <BsX
                        onClick={() => {
                          removeGenreMusic(index);
                        }}
                      />
                    </li>
                  </div>
                ))}
              </div>
            </div>

            <div className="alignTooltip">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Selecione o estilo musical da sua composição. Basta clicar
                    no balão abaixo.
                  </Tooltip>
                }
              >
                <span>
                  <BsQuestionCircle /> Saiba mais
                </span>
              </OverlayTrigger>
            </div>
          </div>

          <div className="genre">
            {genreMusic.filter(music => lower(music.name).includes(lower(word))).map(music => (
              <div key={music.id}>
                <button
                  className={active === music.name ? 'active' : ''}
                  type="button"
                  onClick={() => {
                    addGenreMusic(music.id, music.name)
                    setWord('')
                  }}
                >
                  {music.name}
                </button>
              </div>
            ))}
          </div>

          <S.SelectOption>
            <div
              className={failLetterAndMusic ? 'know active' : 'know'}
              id="myLetter"
            >
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Clique em <strong>Digitar letra</strong> e digite sua
                    composição.
                  </Tooltip>
                }
              >
                <span className="know">
                  <BsQuestionCircle />
                  Saiba mais
                </span>
              </OverlayTrigger>
              {/* {erroLetter && (
                <ShowError description="Você deve escrever a letra da música" />
              )} */}
              <S.ButtonContainer
                onClick={() => setModalShow('write')}
                activeLetter={!(letters.length === 0 || letters[0] === '')}
              >
                {letters.length === 0 || letters[0] === '' ? (
                  <>
                    <BsPencil /> <font className="let">Digitar letra</font>
                  </>
                ) : (
                  <span style={{ color: '#78e5d5' }}>Letra adicionada</span>
                )}
              </S.ButtonContainer>
            </div>



            <div className={failLetterAndMusic ? 'know active' : 'know'}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Caso haja música, clique em <strong>Selecione o áudio</strong>{' '}
                    e faça o upload da sua composição. A música deverá ter tamanho máximo de 10 MB.
                  </Tooltip>
                }
              >
                <span className="know">
                  <BsQuestionCircle />
                  Saiba mais
                </span>
              </OverlayTrigger>

              <div style={{display:'flex', flexDirection: 'column', gap: 10}}>
                <S.ButtonContainer activeLetter={!!selectedFile}>
                  <label htmlFor='selectMP3' className='selectMP3'>
                    {!selectedFile ? (
                      <>
                        <MusicIcon /> <S.UploadMessage>Selecione o áudio (máximo 10 MB)</S.UploadMessage>
                        <input type='file' id='selectMP3' accept='audio/*' onChange={handleFileChange} />
                      </>
                    ) : <>
                          <S.UploadMessage type="success">
                            {selectedFile.file.name}
                          </S.UploadMessage>
                          <input type='file' id='selectMP3' accept='audio/*' onChange={handleFileChange} />
                        </>
                    }
                  </label>
                </S.ButtonContainer>
                {selectedFile && !loading && (
                  <div style={{ border: '1px solid #87868b', borderRadius: '6px', padding: '10px'}}>
                    <span style={{ color: '#87868b'}}>Escutar música escolhida:</span>
                    <AudioPlayer
                      layout="horizontal"
                      customAdditionalControls={[]}
                      showJumpControls={false}
                      customVolumeControls={[]}
                      src={selectedFile.urlMP3}
                    />
                  </div>
                )}
              </div>
            </div>
          </S.SelectOption>

          <S.DivButton>
            <div className="checkbox">
              <Checkbox type="checkbox" name="terms">
                <span className="checkmark">
                  Aceito os{' '}
                  <button
                    className="termsButton"
                    type="button"
                    onClick={() => setModalShow('termsModal')}
                  >
                    termos e condições.
                  </button>
                </span>
              </Checkbox>
            </div>
            <div className="checkbox">
              <Checkbox type="checkbox" name="myAuthor">
                <span className="checkmark">
                  Declaro que essa composição é de minha autoria!
                </span>
              </Checkbox>
            </div>
            {loadingRegister ? (
              <img src={loadingGif} alt="Carregando..." width="50px" />
            ) : (
              // buttonFinish()
              <button type="submit" className="registerMusic">
                Registrar música
              </button>
            )}
          </S.DivButton>
        </Form>
      </S.BackgroundSection>



      {/* Modais */}

      {modalShow === 'registeringMusic' && (
        <Modal isOpen={modalShow}>
          <span style={{margin: '0 auto', fontSize: '1.5rem'}}>Aguarde o registro da música</span>
          <Loading />
        </Modal>
      )}

      {modalShow === 'uploadMusic' && (
        <Modal isOpen={modalShow}>
          <span style={{margin: '0 auto', fontSize: '1.5rem'}}>Aguarde o upload do áudio</span>
          <Loading />
        </Modal>
      )}

      {modalShow === 'termsModal' && (
        <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
          <TermsUse />
        </Modal>
      )}

      {modalShow === 'write' && (
        <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
          <section className="letterSection">
            <div className="headerLetter">
              <span>Digitar letra da música</span>
              <button type="button" onClick={() => fecharSalvarLetter()}>
                <RiSave2Line size={22} />
                Salvar e voltar
              </button>
            </div>
            <div className="divLetterMusic">
              <textarea
                onChange={e => setLetters(e.target.value)}
                value={letters}
                cols="30"
                rows="30"
              />
            </div>
          </section>
        </Modal>
      )}

      {modalShow === 'failLetter' && (
        <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
          <span className="addLetter">
            Você não adicionou nenhuma letra à composição, deseja continuar sem
            a letra?
          </span>
          <div className="addLetter">
            <button
              className="addLetterLeft"
              type="button"
              onClick={() => continueRegisterLetter()}
            >
              Registrar composição sem letra
            </button>
            <button
              className="addLetterRight"
              type="button"
              onClick={() => setModalShow('write')}
            >
              Quero adicionar a letra
            </button>
          </div>
        </Modal>
      )}

      {modalShow === 'failMusic' && (
        <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
          <span className="addMusic">
            Você não adicionou o áudio à composição, deseja continuar sem
            o áudio?
          </span>
          <div className="addMusic">
            <button
              className="addMusicLeft"
              type="button"
              onClick={() => continueRegisterMusic()}
            >
              Registrar composição sem o áudio
            </button>
            <button
              className="addMusicRight"
              type="button"
              onClick={() => setModalShow('')}
            >
              Quero adicionar o áudio
            </button>
          </div>
        </Modal>
      )}
    </Index>
  );
}
