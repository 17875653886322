/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiMail, FiUser, FiLock, FiLogIn, FiPhone } from 'react-icons/fi';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api from '~/services/api';
import Modal from '~/components/Modal';
import { useToast } from '~/hooks/toast';
import loadingGif from '~/assets/img/loadingMusic.gif';

import getValidationErrors from '~/utils/getValidationErrors';

import Checkbox from '~/components/Checkbox';
import Input from '~/components/Input';
import Button from '~/components/Button';

import TermsUse from '~/components/TermsUse';
import InputM from '~/components/InputM';
import { formatTextCapitalize } from '~/utils/formatTextCapitalize';
import {
  BackgroundSectionRegister,
  DivSectionLeftRegister,
  DivSectionRightRegister,
} from './styles';

export default function SignUp() {
  const formRef = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [disable, setDisable] = useState('');
  const [loading, setLoading] = useState(false);

  const [modalShow, setModalShow] = useState('');

  useEffect(() => {
    // Certifique-se de que o fbq está disponível no escopo global
    if (typeof window.fbq !== 'undefined') {
      window.fbq('track', 'CompleteRegistration', {
        content_name: 'Cadastro de Usuário',
        status: 'Sucesso',
      });
    }
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        const validContact = data.contact.replace(/[^0-9]/g, '');

        const schema = Yup.object().shape({
          nickname: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Insira um E-mail válido'),
          contact: Yup.string()
            .required('Número obrigatório'),
          password: Yup.string().required('Insira uma senha').min(6, 'No mínimo 6 dígitos'),
          password_confirmation: Yup.string()
            .required('Insira uma senha')
            .oneOf([Yup.ref('password'), null], 'As senhas devem corresponder'),
          terms: Yup.boolean().oneOf(
            [true],
            'Você deve aceitar os termos e condições'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setDisable(disable === '' ? 'active' : '');
        setLoading(true);

        data.goTo = `${process.env.REACT_APP_URL}/login`;

        const response = await api.post('/auth/signup', {
          ...data,
          nickname: formatTextCapitalize(data.nickname),
          email: data.email.toLowerCase(),
          contact: `+55${validContact}`
          }
        );

        const { message } = response.data;

        setLoading(false);

        history.push(`/validation/${message}`);

        addToast({
          type: 'success',
          title: 'Cadastro realizado!',
          description: 'Verifique seu E-mail!',
        });
      } catch (error) {
        setLoading(false);
        setDisable(disable === 'active' ? '' : '');

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current.setErrors(errors);
          return;
        }

        if (!error.response) {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
              'Serviço de dados indisponível no momento, favor tente mais tarde!',
          });
          return;
        }

        if (error.response.data.errors) {
          const retorno = {};
          // eslint-disable-next-line array-callback-return
          error.response.data.errors.map(res => {
            if (res.field === 'nickname') retorno.nickname = res.message;
            if (res.field === 'email') retorno.email = res.message;
            if (res.field === 'password') retorno.password = res.message;
            if (res.field === 'password_confirmation')
              retorno.password_confirmation = res.message;
            if (res.field === 'terms') retorno.terms = res.message;

            addToast({
              type: 'error',
              title: 'Erro no cadastro',
              description: `${res.message}`,
            });
          });

          formRef.current.setErrors(retorno);
          return;
        }

        const messageContactExists = error.response.data.message;

        if (messageContactExists) {
          const retorno = {};
          retorno.contact = messageContactExists
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: `${messageContactExists}`,
          });
          formRef.current.setErrors(retorno);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Tente novamente',
        });
      }
    },
    [addToast, disable, history]
  );


  return (
    <BackgroundSectionRegister>
      <DivSectionLeftRegister>
        <span>Crie uma conta grátis</span>
        <p>
          ou <Link to="/login">acesse sua conta</Link>
        </p>
      </DivSectionLeftRegister>
      <DivSectionRightRegister>
        <p>Cadastre-se abaixo</p>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            name="nickname"
            icon={FiUser}
            type="name"
            placeholder="Nome artístico"
            textTranform="capitalize"
          />

          <Input
            name="email"
            icon={FiMail}
            type="email"
            placeholder="Email"
            textTranform="lowercase"
          />

          <InputM
            name="contact"
            icon={FiPhone}
            type="tel"
            mask='(99) 99999-9999'
            placeholder="Celular"
          />

          <Input
            name="password"
            icon={FiLock}
            type="password"
            placeholder="Senha"
          />

          <Input
            name="password_confirmation"
            icon={FiLock}
            type="password"
            placeholder="Confirmar senha"
          />
          <div className="termos">
            <Checkbox type="checkbox" name="terms">
              <span>
                Aceito os{' '}
                <button type="button" onClick={() => setModalShow('terms')}>
                  termos e condições
                </button>
                {modalShow === 'terms' && (
                  <Modal
                    isOpen={modalShow}
                    onClickClose={() => setModalShow('')}
                  >
                    <TermsUse />
                  </Modal>
                )}
              </span>
            </Checkbox>
          </div>
          <Button type="submit" disabled={loading} className={disable}>
            Continuar
          </Button>
          {loading && <img src={loadingGif} alt="Carregando..." width="50px" />}
        </Form>
        <Link to="/login" className="returnLogin">
          <FiLogIn /> Fazer login
        </Link>
      </DivSectionRightRegister>
    </BackgroundSectionRegister>
  );
}
