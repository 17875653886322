import React, { useRef, useCallback, useState, useEffect } from 'react';
// import QRCode from "react-qr-code";
import { useParams } from 'react-router-dom';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Form } from '@unform/web';
import { FiFile } from 'react-icons/fi';
// import { BsQuestionCircle } from 'react-icons/bs';
import * as Yup from 'yup';

import api from '~/services/api';
import { useToast } from '~/hooks/toast';
import getValidationErrors from '~/utils/getValidationErrors';
import Input from '~/components/Input';
import Button from '~/components/Button';

import loadingGif from '~/assets/img/loadingMusic.gif';
import Check from '~/assets/img/check.svg';
import * as S from './styles';
// import avatar from '~/assets/img/avatar.png';


const ValidacaoCertificado = () => {
  const { hashAcordeon } = useParams();
  const formRef = useRef(null);

  // const history = useHistory();
  const { addToast } = useToast();

  const [disable, setDisable] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState(false);

  const [dataInfoCertificate, setdataInfoCertificate] = useState({
    fileCertificate: '',
    fileMusic: ''
  });

  const findCertificateByHash = useCallback(async (data) => {

    try {
      setLoading(true);
      setShowSection(false);
      setDisable(disable === '' ? 'active' : '');

      // console.log("URL", `certificate/validate/${data.hashAcordeon}`)
      const response = await api.get(`guest/validate/${data.hashAcordeon}`);
      const result = response.data;
      // console.log(result)
      setdataInfoCertificate({
        fileCertificate: result.fileCertificate,
        fileMusic: result.fileMusic,
        linkMusic: result.linkMusic,
        linkFileP7S: result.linkFileP7S,
        linkITI: result.linkITI
      })

      setLoading(false);
      setShowSection(true);
      setDisable(disable === 'active' ? '' : '');
      return;

    } catch (error) {
      setLoading(false);
      setShowSection(false);
      setDisable(disable === 'active' ? '' : '');

      if (error.message === 'Network Error') {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'Serviço de dados indisponível no momento, favor tente mais tarde!',
        });
        return;
      }

      if (error.message) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: error.response ?
          error.response.data.message : 'Erro ao validar certificado',
        });
      }

    }
  }, [addToast, disable])

  useEffect(() => {

    if(hashAcordeon) {

      formRef.current.setData({
        hashAcordeon
      })

      findCertificateByHash({hashAcordeon})
    } else {
      formRef.current.setData({})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        // console.log(data);
        const schema = Yup.object().shape({
          hashAcordeon: Yup.string()
            .required('Digite o código hash')
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await findCertificateByHash(data)
      } catch (err) {

        setLoading(false);
        setShowSection(false);
        setDisable(disable === 'active' ? '' : '');

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current.setErrors(errors);

        }
      }

    },
    [findCertificateByHash, disable]
  );


  return (
    <>
      <S.Header>
        <div className="nameMusic">
          <h1>Validação de Certificado</h1>
        </div>
      </S.Header>


      {showSection ? (
        <S.Section>
          <S.DivSectionGlobal>
            <div className="sectionSucess">
                <img src={Check} alt="certificado valido" />
                <span className="sucess">Certificado Válido</span>
            </div>
            <hr size="20"/>

            <p style={{padding: '0px 0px 25px'}}>
              Este certificado é válido, obedecendo rigorosamente a LEI 9.610/98 e a medida
              provisória nº 2.200-2, que estabeleceu o ICP Brasil.
            </p>

            <h1>Dados do Certificado</h1>

            <div className='infoCertificate'>
              <div className='dataForm'>
                <Form id='formInfo' style={{width: '100%'}}>

                  <div className="inputsRow">
                    <div className="divColumn48">
                      <Input label="Certificado:" type="text"
                        value={dataInfoCertificate.fileCertificate}
                        name="fileCertificate" id="fileCertificate"
                        maxLength="25" disabled style={{
                          cursor: 'no-drop',
                          color: '#7C7C7D',
                        }}/>
                    </div>
                  </div>
                  {/* <div className="inputsRow">
                    <div className="divColumn48">
                      <Input label="Música:" type="text"
                        value={dataInfoCertificate.fileMusic}
                        name="fileMusic" id="fileMusic"
                        maxLength="25" disabled style={{
                          cursor: 'no-drop',
                          color: '#7C7C7D',
                        }}/>
                    </div>
                  </div> */}
                </Form>
              </div>
              <div className='dataQR'>
                {/* <div>
                  <QRCode value={dataInfoCertificate.linkITI} size={145} level="Q"/>
                  <span style={{padding: '10px 0px 0px'}}>VERIFICADOR DE CONFORMIDADE</span>
                </div> */}
              </div>
            </div>

            <div className='footerButtons'>
              <div className='sectionButtons'>
                <a href={dataInfoCertificate.linkMusic}>Visualizar Composição</a>
                <a href={dataInfoCertificate.linkFileP7S} download>Baixar Arquivo P7S</a>
                <a href="https://validar.iti.gov.br">Verificador de Conformidade</a>
                {/* <a className='link03' href={dataInfoCertificate.linkITI}>Verificador de Conformidade</a> */}
              </div>
              {/* <div className='sectionSaibamais'>
                <div className="alignTooltip">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip className="mytooltip">
                        O que é e pra que server a assinatura Cades ou o Pades? is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                      </Tooltip>
                    }
                  >
                    <span variant="secondary">
                      <BsQuestionCircle /> Saiba mais
                    </span>
                  </OverlayTrigger>
                </div>
              </div> */}
            </div>
          </S.DivSectionGlobal>
        </S.Section>
      ) : (<div />)}

      <S.Section>
        <S.DivSectionGlobal>
          <h1>Certificado Acordeon</h1>
          <p>Digite o código hash do certificado para validar a autenticidade do mesmo em nosso sistema e para ter acesso aos arquivos de assinatura.</p>

          <Form id='formHash' ref={formRef} onSubmit={handleSubmit}  autocomplete="off">
            <Input icon={FiFile} name="hashAcordeon" id="hashAcordeon" type="text" placeholder="Código Hash" />

            {loading && <img src={loadingGif} alt="Carregando..." width="50px" />}
            <Button type="submit" disabled={loading} className={disable}>
              Validar
            </Button>
          </Form>

        </S.DivSectionGlobal>
      </S.Section>

    </>
  );
};

export default ValidacaoCertificado;
