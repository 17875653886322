import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { FiShare2 } from 'react-icons/fi';

// import avatar from '~/assets/img/avatar.png';

import api from '~/services/api';
import { useToast } from '~/hooks/toast';
import * as S from './styles';
// import { useProfile } from '~/hooks/profile';

const RecordedMusic = () => {
  const { key } = useParams();
  // const { dataProfile } = useProfile();

  const { addToast } = useToast();
  const history = useHistory();
  const [dataMusic, setDataMusic] = useState();
  const [MP3, setMP3] = useState();
  const [extension, setExtension] = useState();
  const [musicDownload, setMusicDownload] = useState();
  const [musicRegister, setMusicRegister] = useState();

  // useEffect(() => {
  //   const redirectMusic = () => {
  //     dataProfile?.key &&
  //     history.push(`/dashboard/musica-registrada/${key}`)
  //   }
  //   redirectMusic();
  // }, [dataProfile, history, key])

  useEffect(() => {

    if (key) {
      api
        .get(`/guest/music/${key}`)
        .then(res => {
          setDataMusic(res.data);

          const type = res.data.file.name.split('.').pop();

          setExtension(type);

          if (res.data.linkStream) {
            const urlStream = `${process.env.REACT_APP_URL_API}/api/v1/guest/music/stream/${res.data.key}`;
            const handleDownloadMusic = `${process.env.REACT_APP_URL_API}/api/v1/guest/music/stream/${res.data.key}`;
            const urlMusicRegister = `${process.env.REACT_APP_URL}/musica-registrada/${res.data.key}`;

            setMP3(urlStream);
            setMusicDownload(handleDownloadMusic);
            setMusicRegister(urlMusicRegister);
          }

          // addToast({
          //   type: 'success',
          //   title: `message`,
          //   description: `Faça login com o email e sua senha`,
          // });
        })
        .catch(error => {
          // const { message } = error.response.data;
          console.log(error);

          history.push('/dashboard/inicio/resumo');

          addToast({
            type: 'error',
            title: `Música não encontrada`,
            description: `Erro ao tentar acessar a música`,
          });
        });
    }
  }, [key, addToast, history]);

  const downloadMusic = () => {
    window.open(musicDownload, '_blank');
  };

  const sharedMusic = () => {
    window.open(`http://api.whatsapp.com/send?text=${musicRegister}`, '_blank');
  };

  const TelFormated = dataMusic?.profile.contact.replace(/^([\d]{2})([\d]{5})([\d]{4})/, "($1) $2-$3");

  return (
    <>
      <S.Header>
        <div className="nameMusic">
          <h1>{dataMusic?.title}</h1>
          <span>{dataMusic?.style}</span>
        </div>
        <div className="author">
          {dataMusic?.profile.avatar !== null && (
            <div className="holder">
              <img src={dataMusic?.profile.avatar} alt="Foto do compositor" />
            </div>
          )}
          <div>
            <span>Música de</span>
            <h1>{dataMusic?.profile.nickname}</h1>
            <span className="contact">
              Contato: {TelFormated}
            </span>
          </div>
        </div>
      </S.Header>
      <S.BackgroundSection>
        <div className="align">
          <div className="share">
            <button type="button" aria-label="Compartilhar música" onClick={sharedMusic}>
              Compartilhar
              <FiShare2 size={20} />
            </button>
          </div>

          <Table borderless>
            <tbody>
              <tr>
                <td className="leftItens">
                  <span className="nameMusic">{dataMusic?.title}</span>
                  <span className="nameComposer">
                    {dataMusic?.profile.nickname} (Autor)
                  </span>
                  {dataMusic?.co_author.map((co, index) => (
                    <span key={index} className="nameComposer">
                      {co.name} (Co-autor)
                    </span>
                  ))}
                  {dataMusic?.interpreter.map((co, index) => (
                    <span key={index} className="nameComposer">
                      {co.name} (Intérprete)
                    </span>
                  ))}
                </td>
                {extension === 'txt' ? (
                  <td>
                    <span>Composição sem áudio</span>
                  </td>
                ) : (
                  <>
                    <td className="audioPlay">
                      <AudioPlayer
                        layout="horizontal"
                        customAdditionalControls={[]}
                        showJumpControls={false}
                        customVolumeControls={[]}
                        src={MP3}
                      />
                    </td>
                    <td className="iconDownloadMusic">
                      <button type="button" onClick={downloadMusic}>
                        Baixar
                      </button>
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </Table>
          {/* <div className="divAlignMusics">
            <div className="co-authors">
              {dataMusic?.co_author?.map((co, index) => (
                <font key={index}>{co.name}</font>
              ))}
            </div>
            <div className="interprets">
              {dataMusic?.interpreter?.map((co, index) => (
                <font key={index}>{co.name}</font>
              ))}
            </div>
          </div> */}
          <div className="letterMusic">
            <span>LETRA:</span>
            {dataMusic?.letter.map((letra, index) => (
              letra !== '' ?
                <p key={index}>
                  {letra}
                </p>
              :
                <br/>
            ))}
          </div>
        </div>
      </S.BackgroundSection>
    </>
  );
};

export default RecordedMusic;
